import React from "react";
// import { Images } from "../../../assets/ImagesGroup";
import { Col, Container, Row } from "react-bootstrap";

export default function Footer() {
  return (
    <div className="footer">
      <Container fluid>
        <Row className="position-relative">
          <Col className="d-flex align-items-center justify-content-start">
            © 2024 Ministry of Health & Family Welfare, Govt. of India. All
            Rights Reserved.
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            Developed by: Primus Partners &nbsp;{" "}
            {/* <Navbar.Brand
              href="http://www.pmis.com"
              target="_blank"
              className="footer-logo gap-4"
              rel="noreferrer"
            >
              <Image src={Images.LOGO_W} alt="Logo" />
            </Navbar.Brand> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
