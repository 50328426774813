import React, { useEffect, useState } from "react";

import { Chart } from "react-google-charts";
import { useChartData } from "../../../hooks/useChart";
import Loader from "../../../components/Loader";

const options = {
  height: 550,
  percentEnabled: true,
  gantt: {
    trackHeight: 40,
    percentEnabled: true,
    barHeight: 38,
    barCornerRadius: 0,
    labelStyle: {
      fontName: "Arial",
      fontSize: 12,
      color: "#3D3A4B",
    },
  },
};

let formattedData = null;

let filter = [];

const GanttChart = ({ selectedProject }) => {
  const [chartFormattedData, setChartFormattedData] = useState([]);

  const {
    data: chart_data,
    isLoading,
    refetch,
  } = useChartData({
    id: process.env.REACT_APP_GANTT_DATASET_ID,
    columns: [
      "task_id",
      "task_name",
      "start_date",
      "end_date",
      "project_title",
      "percent_complete",
      "duration",
    ],
    filter,
  });

  useEffect(() => {
    filter = selectedProject
      ? [{ col: "project_title", op: "==", val: selectedProject }]
      : [];
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  useEffect(() => {
    if (chart_data) {
      const transformedData = [
        [
          { type: "string", label: "Task ID" },
          { type: "string", label: "Task Name" },
          { type: "string", label: "Resource" },
          { type: "date", label: "Start Date" },
          { type: "date", label: "End Date" },
          { type: "number", label: "Duration" },
          { type: "number", label: "Percent Complete" },
          { type: "string", label: "Dependencies" },
        ],
        ...chart_data?.result[0]?.data?.map((task) => [
          task.task_id,
          task.task_name,
          task.task_name,
          new Date(task.start_date),
          new Date(task.end_date),
          task.duration,
          task.percent_complete * 100,
          null,
        ]),
      ];

      setChartFormattedData(transformedData);
    }
  }, [chart_data]);

  formattedData = chartFormattedData?.map((row, index) => {
    if (index === 0) return row;
    return row;
  });

  return (
    <>
      {isLoading && <Loader />}

      {formattedData && (
        <Chart
          chartType="Gantt"
          width="calc(100% - 1px)"
          data={formattedData}
          options={options}
        />
      )}
    </>
  );
};

export default GanttChart;
