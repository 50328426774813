import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  fetchCSRFToken,
  fetchGuestToken,
  fetchLoginToken,
} from "../services/api/loginAPI";

export const useAuth = () => {
  const fetchLoginAuth = useMutation({
    mutationFn: fetchLoginToken,
  });
  return { fetchLoginAuth };
};

export const useCSRFTOKEN = () => {
  return useQuery({
    queryKey: ["csrftoken"],
    queryFn: fetchCSRFToken,
    staleTime: 5 * 60 * 1000, // Prevent frequent re-fetching
    enabled: false, // Disable automatic fetching
  });
};

export const useGuestToken = () => {
  const fetchGuestAuthentication = useMutation({
    mutationFn: fetchGuestToken,
    onSuccess: (data) => {
      localStorage.setItem("guest_token", data.token);
    },
    onError: (error) => {
      toast.error(error.response.data.message || "Guest token api failed");
    },
  });

  return { fetchGuestAuthentication };
};
