import React from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { useRecoilState } from "recoil";
import accessTokenAtom from "../store/atoms/loginAtoms";
export default function AppRouter() {
  const isAuthenticated = localStorage.getItem("access_token_superset");
  const [accessTokenState] = useRecoilState(accessTokenAtom);

  return (
    <>
      {isAuthenticated && accessTokenState ? (
        <PrivateRoutes />
      ) : (
        <PublicRoutes />
      )}
    </>
  );
}
