import React, { useEffect, useRef, useState } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { ENV } from "../../../env";
import { useGuestToken } from "../../../hooks/useAuth";
import Layout from "../layout/Layout";
import Loader from "../../../components/Loader";

const dataGuest = {
  resources: [
    {
      id: process.env.REACT_APP_DASHBOARD_2_KEY,
      type: process.env.REACT_APP_DASHBOARD_2_TYPE,
    },
  ],
  rls: [],
  user: {
    first_name: process.env.REACT_APP_DASHBOARD_2_FIRSTNAME,
    last_name: process.env.REACT_APP_DASHBOARD_2_LASTNAME,
    username: process.env.REACT_APP_DASHBOARD_2_USER,
  },
};

const ProjectOverviewDashboard = () => {
  const dashboardRef = useRef(null);
  const [isLoadDashboard, setLoadDashboard] = useState(false);
  const { fetchGuestAuthentication } = useGuestToken();
  const { data: guest_token, isPending, isSuccess } = fetchGuestAuthentication;

  useEffect(() => {
    fetchGuestAuthentication.mutate(dataGuest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (guest_token && isSuccess) {
      setLoadDashboard(true);
    }
  }, [guest_token, isSuccess]);

  useEffect(() => {
    const token = localStorage.getItem("guest_token");

    if (!token) {
      console.log("No token found. Please login first.");
      return;
    }

    const loadDashboard = async () => {
      try {
        await embedDashboard({
          id: process.env.REACT_APP_DASHBOARD_2_KEY,
          supersetDomain: ENV.WAPPER_API_BASE_URL,
          mountPoint: dashboardRef.current,
          fetchGuestToken: () => {
            return `${token}`;
          },
          iframeSandboxExtras: [
            "allow-top-navigation",
            "allow-popups-to-escape-sandbox",
          ],
        });
      } catch (error) {
        console.log("Error loading dashboard:", error);
      }
    };
    loadDashboard();
  }, [isLoadDashboard]);

  return (
    <>
      {isPending && <Loader />}
      <Layout>
        <div className="superset border-top border-dark">
          {isLoadDashboard && <div ref={dashboardRef} />}
        </div>
      </Layout>
    </>
  );
};

export default ProjectOverviewDashboard;
