import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

import Loader from "../../../components/Loader";

import { useAuth, useCSRFTOKEN } from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import accessTokenAtom from "../../../store/atoms/loginAtoms";
import { useNavigate } from "react-router-dom";

const initialFormValues = {
  password: "",
  provider: "db",
  refresh: true,
  username: "",
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "Username must be at least 4 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
});

const Login = () => {
  const { data: csrf_token, refetch, isLoading } = useCSRFTOKEN();
  const { fetchLoginAuth } = useAuth();
  const {
    data: loginData,
    isPending,
    isSuccess,
    isError,
    error,
  } = fetchLoginAuth;

  const setAccessTokenState = useSetRecoilState(accessTokenAtom);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoginSuccess = async () => {
      if (isSuccess) {
        localStorage.setItem("access_token_superset", loginData.access_token);
        localStorage.setItem("refresh_token_superset", loginData.refresh_token);

        const result = await refetch();
        if (result.data) {
          localStorage.setItem("csrf_token", result.data);
          toast.success("Logged in successfully");
          setAccessTokenState(true);

          navigate("/schemeoverview");
        }
      }

      if (isError) {
        toast.error(
          error?.response?.data?.message || "Login failed. Please try again."
        );
      }
    };

    handleLoginSuccess();
  }, [
    isSuccess,
    isError,
    loginData,
    error,
    setAccessTokenState,
    navigate,
    refetch,
  ]);

  useEffect(() => {
    if (csrf_token) {
      localStorage.setItem("csrf_token", csrf_token.result);
    }
  }, [csrf_token]);

  const handleSubmit = (e, payload) => {
    e.preventDefault();
    fetchLoginAuth.mutate(payload);
  };

  return (
    <>
      {(isPending || isLoading) && <Loader />}
      <div className="userlogin">
        <div className="login">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col md={6} className="d-flex align-items-center">
                <div className="login-punchline"></div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col md={5}>
                <div className="login-form">
                  <div className="login-formheading">PMIS Dashboard Login</div>
                  <Formik
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                  >
                    {({ isSubmitting, values, handleChange }) => (
                      <Form noValidate>
                        <Form.Group controlId="formEmail" className="mb-2">
                          <Form.Label>user name</Form.Label>
                          <FormControl
                            type="text"
                            size="lg"
                            placeholder="Enter your email"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="error-message"
                          />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-4">
                          <Form.Label>Password</Form.Label>
                          <FormControl
                            type="password"
                            size="lg"
                            placeholder="Enter your password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-message"
                          />
                        </Form.Group>

                        <div className="btn-wrapper justify-content-center align-items-center mt-3">
                          <Button
                            type="button"
                            onClick={(e) => handleSubmit(e, values)}
                            className="btn btn-primary "
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Logging in..." : "Login"}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Login;
