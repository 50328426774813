import { useQuery } from "@tanstack/react-query";
import { fetchChartData } from "../services/api/chartAPI";

export const useChartData = (params) => {
  return useQuery({
    queryKey: ["fetchChartData", params],
    queryFn: () => fetchChartData(params),
    staleTime: 5 * 60 * 1000,
    enabled: true,
  });
};
