import axios from "axios";
import { ENDPOINTS } from "../../env";
import axiosInstance from "../axiosInstance";

export const fetchLoginToken = async (credentials) => {
  try {
    const response = await axios.post(ENDPOINTS.LOGIN, credentials, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
};
export const logoutPanel = () => {
  localStorage.removeItem("access_token_superset");
  localStorage.removeItem("refresh_token_superset");
  localStorage.removeItem("csrf_token");
  localStorage.removeItem("guest_token");
  localStorage.removeItem("recoil-persist");
  window.location.href = "/";
};
export const refreshAccessToken = async (refreshToken) => {
  try {
    const refreshPayload = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };
    const response = await axios.post(ENDPOINTS.REFRESH_TOKEN, refreshPayload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    localStorage.setItem("access_token_superset", response.data.access_token);
    localStorage.setItem("refresh_token_superset", response.data.refresh_token);
    return response.data.access_token;
  } catch (error) {
    throw error;
  }
};

export const fetchCSRFToken = async () => {
  const response = await axiosInstance.get(ENDPOINTS.CSRF_TOKEN);
  return response.data.result;
};

export const fetchGuestToken = async (credentials) => {
  const csrf_token = localStorage.getItem("csrf_token");

  try {
    const response = await axiosInstance.post(
      ENDPOINTS.GUEST_TOKEN,
      credentials,
      {
        headers: {
          "X-CSRFToken": csrf_token,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error(
        `Error fetching Guest token: ${error.response.status} - ${
          error.response.data.message || error.message
        }`
      );
    } else if (error.request) {
      console.error("Error: No response received from server", error.request);
    } else {
      console.error("Error fetching Guest token:", error.message);
    }

    logoutPanel();

    throw error;
  }
};
