import React from "react";
import Footer from "./Footer";
// import Sidebar from "./Sidebar";
import Header from "./Header";

export default function Layout({ rightSide, searchBox, children }) {
  // const [sidebarActive] = useState(false);

  return (
    <>
      <div className="layout">
        <div className="layout-header">
          <Header />
        </div>
        <div className="layout-main">
          {/* <div className={`layout-sidebar ${sidebarActive ? "active" : ""}`}>
            <Sidebar />
          </div> */}
          <div className="layout-content scrollbardesign">{children}</div>
        </div>
        <div className="layout-footer">
          <Footer />
        </div>
      </div>
    </>
  );
}
