const getEnvVar = (key, defaultValue = "") => {
  if (process.env[key]) {
    return process.env[key];
  }
  console.warn(`Environment variable ${key} is not set, using default value.`);
  return defaultValue;
};

export const ENV = {
  WAPPER_API_BASE_URL: getEnvVar("REACT_APP_WARPPER_API_BASE_URL"),
};

export const ENDPOINTS = {
  LOGIN: `${ENV.WAPPER_API_BASE_URL}api/v1/security/login`,
  CSRF_TOKEN: `${ENV.WAPPER_API_BASE_URL}api/v1/security/csrf_token/`,
  GUEST_TOKEN: `${ENV.WAPPER_API_BASE_URL}api/v1/security/guest_token/`,
  REFRESH_TOKEN: `${ENV.WAPPER_API_BASE_URL}api/v1/security/refresh`,
  CHART_DATA: `${ENV.WAPPER_API_BASE_URL}api/v1/chart/data`,
};
