import post from "./images/placeholders/placeholder-post-1.svg";
import slide from "./images/placeholders/placeholder-slide-1.svg";
import user from "./images/placeholders/placeholder-user-4.svg";
import bars from "./images/bars.png";
import mohfw from "./images/mohfw.png";
import primuspartnerslogo from "./images/primuspartnerslogo.png";
import primuspartnerswhitelogo from "./images/primuspartners-white-logo.png";
import greateplacetowork from "./images/greatplace.png";
export const Images = Object.freeze({
  LOGO: primuspartnerslogo,
  LOGO_W: primuspartnerswhitelogo,
  MOHFW: mohfw,
  POST: post,
  SLIDE: slide,
  USER: user,
  BARS: bars,
  GPTW: greateplacetowork,
});
