import { ENDPOINTS } from "../../env";
import axiosInstance from "../axiosInstance";

export const fetchChartData = async (body) => {
  const payload = {
    datasource: {
      id: body.id,
      type: "table",
    },
    queries: [
      {
        columns: body.columns,
        filters: body.filter,
      },
    ],
  };
  try {
    const response = await axiosInstance.post(ENDPOINTS.CHART_DATA, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching chart data:", error);
    throw error;
  }
};
