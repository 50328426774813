import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import GanttChart from "./GanttChart";
import { FormGroup, FormLabel } from "react-bootstrap";
import Select from "react-select";
import { useChartData } from "../../../hooks/useChart";
import Loader from "../../../components/Loader";

const ProjectPlanViewCharts = () => {
  const { data: chart_data, isLoading } = useChartData({
    id: process.env.REACT_APP_GANTT_DATASET_ID,
    columns: [
      "task_id",
      "task_name",
      "start_date",
      "end_date",
      "project_title",
      "percent_complete",
      "duration",
    ],
    filter: [],
  });
  const [projectOption, setProjectOption] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  useEffect(() => {
    if (chart_data) {
      const projectTitles = new Set(
        chart_data?.result[0]?.data?.map((item) => item.project_title)
      );
      setProjectOption(
        Array.from(projectTitles).map((item) => ({ label: item, value: item }))
      );
    }
  }, [chart_data]);

  return (
    <>
      {isLoading && <Loader />}
      <Layout>
        <div className="superset border-top border-dark">
          <div className="superset-rightside">
            <div className="heading-1 d-flex align-items-center justify-content-between">
              Filters <i className="icon-filter"></i>
            </div>
            <div>
              <hr />
            </div>
            <div>
              <div className="mb-3">
                <FormGroup className="form-control-wrap">
                  <FormLabel>Project Name</FormLabel>
                  <Select
                    name={`project_title`}
                    options={projectOption}
                    placeholder="Projects Name"
                    className="react-select select-type-2 select-type-4"
                    classNamePrefix="react-select"
                    onChange={(selectValue) => {
                      setSelectedProject(selectValue.value);
                    }}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="superset-mainside">
            <div className="center-all mb-3">
              <div className="d-flex gap-5">
                <div className="superset-complete d-flex center-all gap-2">
                  Completed
                </div>
                <div className="superset-notcomplete d-flex center-all gap-2">
                  Not Completed
                </div>
              </div>
            </div>
            <div className="superset-chart scrollbardesign pe-3">
              <div>
                <GanttChart selectedProject={selectedProject} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProjectPlanViewCharts;
