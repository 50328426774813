import React from "react";
import { Watch } from "react-loader-spinner";

function Loader({ status }) {
  return (
    <>
      <div className="loader loader-fullscreen">
        <Watch
          height="60"
          width="60"
          radius="48"
          color="#ffffff"
          ariaLabel="watch-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </>
  );
}

export default Loader;
