import React from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { logoutPanel } from "../../../services/api/loginAPI";

function LogoutModal({ setShowModal, showModal }) {
  const logout = () => {
    logoutPanel();
    setShowModal(false);
  };

  return (
    <Modal className="modal type-1" show={showModal}>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="heading-4 center-all my-4">
              Are you sure, you want to Logout?
            </div>
            <div className="btnwrap my-4">
              <Button
                variant="secondary"
                className="medium type-2"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                className="medium type-2"
                onClick={logout}
              >
                Yes
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default LogoutModal;
