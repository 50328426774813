import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SchemeOverviewDashboard from "../pages/private/superset/SchemeOverviewDashboard";
import ProjectOverviewDashboard from "../pages/private/superset/ProjectOverviewDashboard";
import ProjectPlanViewCharts from "../pages/private/superset/ProjectPlanViewCharts";

export default function PrivateRoutes() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/schemeoverview" element={<SchemeOverviewDashboard />} />
          <Route
            path="/projectoverview"
            element={<ProjectOverviewDashboard />}
          />
          <Route path="/projectplanview" element={<ProjectPlanViewCharts />} />
          <Route path="/" element={<Navigate to="/schemeoverview" replace />} />
          <Route path="*" element={<Navigate to="/schemeoverview" replace />} />
        </Routes>
      </Router>
    </>
  );
}
