import React, { useState } from "react";
import { Container, Button, Image, Navbar, Nav } from "react-bootstrap";
import LogoutModal from "./LogoutModal";
import { Images } from "../../../assets/ImagesGroup";
import { NavLink } from "react-router-dom";

export default function Header() {
  const [showModal_1, setShowModal_1] = useState(false);
  return (
    <>
      <LogoutModal setShowModal={setShowModal_1} showModal={showModal_1} />
      <div className="header border-bottom border-dark">
        <Navbar bg="white" expand="lg">
          <Container fluid>
            <Navbar.Brand
              href="http://www.pmis.com"
              target="_blank"
              className="header-logo gap-4"
              rel="noreferrer"
            >
              <Image src={Images.MOHFW} alt="Logo" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarNav" />
            <div className="d-flex align-items-center gap-4">
              <Navbar.Collapse id="navbarNav">
                <Nav className="ms-auto gap-4">
                  <NavLink to="/schemeoverview" activeclassname="active">
                    Scheme Overview
                  </NavLink>
                  <NavLink to="/projectoverview" activeclassname="active">
                    Project Overview
                  </NavLink>
                  <NavLink to="/projectplanview" activeclassname="active">
                    Project Plan View
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
              <Button
                variant="primary"
                className="btn  type-3"
                onClick={() => {
                  setShowModal_1(true);
                }}
              >
                Logout
              </Button>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
