import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const accessTokenAtom = atom({
  key: "accessTokenState",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export default accessTokenAtom;
